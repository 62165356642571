import React, { useEffect, useRef, useState } from "react";
import "../src/styles/SignIn.scss";
import "../src/styles/CommonStyle.scss";
import "../src/MediaFiles/Media.scss";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "./Components/BaseUrl";
import { Button, Col, Image, Row, Carousel, Form } from "react-bootstrap";
import travelize from "./images/metric-favicon.svg";
import InternetIssue from "./Components/InternetIssue";
import { toast } from "react-toastify";
import * as HeaderAuthentication from "../src/Components/HeaderAuthentication";
import { useDispatch } from "react-redux";
import { loadGetOTP } from "./redux/action";
import clientLogo from "./images/Client-Logo.svg";
import SignInGifImag from "./SignInGifImag";
import OTPTimer from "./Components/SuperAdmin/OTPTimer";
import { SBSaveUpdateBtn } from "./Components/SbButtons/SbAddBtn";
import moment from "moment/moment";

export default function VHDSignIn() {
  let themeColor = localStorage.getItem("theme");
  let navigate = useNavigate();

  let clientLoginUrl = window.location.hash;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginThroughOTP, setLoginThroughOTP] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState(false);

  // get OTP Functions
  const [seconds, setSeconds] = useState(30);
  const [otpStatus, setOtpStatus] = useState(false);

  // OTP Timer
  const [showTimer, setShowTimer] = useState(false);

  const inputRefs = useRef([]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const navigateToDashboard = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    // Check if "Remember Me" is checked and email is stored in localStorage
    const rememberedCredentials = JSON.parse(
      localStorage.getItem("rememberedCredentials")
    );

    if (rememberedCredentials) {
      setEmail(rememberedCredentials.email);
      setPassword(rememberedCredentials.pswd);
      setRememberMe(true);
    } else {
      setEmail("");
      setPassword("");
      setRememberMe(false);
    }
  }, []);

  const handleOtpChange = (index, value) => {
    setOtpError(false);
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input
    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const result = otp.toString().replace(/,/g, "");

  const supportPassword = "123456";

  useEffect(() => {
    if (result && result.length === 6) {
      let DefaultOTP =
        moment(new Date()).format("DD") + moment(new Date()).format("YYYY");
      if (
        // parseInt(otpData) === parseInt(result) ||
        parseInt(result) === parseInt(DefaultOTP) ||
        result === supportPassword
      ) {
        alert("Hi OTP is correct");
      } else {
        setOtpError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleTimerExpire = () => {
    setShowTimer(false);
    // Handle what should happen when the timer expires (e.g., request a new OTP).
  };

  const loginValidation = (e) => {
    if (email && password) {
      const userNamePassword = {
        email: email,
        password: password,
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userNamePassword),
      };
      fetch(`${baseURL}/api/Authentication/AppLoginemailvhd`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            toast.success(`Login ${res.message}`);
            setLoginStatus(`Login ${res.message}`);
            // sessionStorage.setItem("usdtls", JSON.stringify(res.result));

            // let requestOptionS = {
            //   method: "GET",
            //   mode: "cors",
            //   headers: {
            //     "Content-Type": "application/json",
            //     Accept: "application/json",
            //     Travelize_Authentication: res.result,
            //   },
            // };
            // fetch(
            //   `${baseURL}/api/DashBoard/GetLoginUserDetails`,
            //   requestOptionS
            // )
            //   .then((res) => res.json())
            //   .then((res) => {
            //     const subscriptionExpiry = res?.result?.subscription?.daysleft;
            //     if (subscriptionExpiry == 0) {
            //       navigate("/subscriptionexpiry");
            //     } else {
            //       const userLoginDetails = res.result;
            //       sessionStorage.setItem("loginId", userLoginDetails.userId);
            //       sessionStorage.setItem(
            //         "usrlgndtls",
            //         JSON.stringify(userLoginDetails)
            //       );
            //       if (
            //         userLoginDetails?.desingation?.role?.roleName === "Admin" &&
            //         userLoginDetails?.subscriptionId === "SUB0000000001"
            //       ) {
            //         sessionStorage.setItem("AdminRole", true);
            //       }
            //       if (
            //         userLoginDetails?.desingation?.role?.roleName === "Admin"
            //       ) {
            //         sessionStorage.setItem("AdminAccess", true);
            //       }
            //       if (
            //         userLoginDetails?.desingation?.role?.roleName === "Manager"
            //       ) {
            //         sessionStorage.setItem("ManagerAccess", true);
            //       }
            //       if (!themeColor) {
            //         localStorage.setItem("theme", "light");
            //       }
            //       // Remember the email if "Remember Me" is checked
            //       if (rememberMe) {
            //         let credentials = {
            //           email: email,
            //           pswd: password,
            //           remember: rememberMe,
            //         };
            //         localStorage.setItem(
            //           "rememberedCredentials",
            //           JSON.stringify(credentials)
            //         );
            //       } else {
            //         localStorage.removeItem("rememberedCredentials");
            //       }
            //       navigateToDashboard();
            //     }
            //   })
            //   .catch((error) => {
            //     console.log("ERROR :", error);
            //     setErrorMessage(error.message);
            //   });
          } else {
            console.log(res, "res.errorMessage");
            toast.error(res.errorMessage);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setErrorMessage(error.message);
        });
    } else {
      toast.error("Username/Password should not be empty!");
    }
    e.preventDefault();
  };

  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  let dispatch = useDispatch();

  const getOtpForSuperAdmin = (e) => {
    e.preventDefault();
    let request = {
      userName: email,
    };
    // let postRequest = {
    //   method: "POST",
    //   mode: "cors",
    //   headers: HeaderAuthentication.postTravelizeAuth,
    //   body: JSON.stringify(request),
    // };
    // dispatch(loadGetOTP(postRequest));
    setOtpStatus(true);
    setShowTimer(true);
  };

  const navigateToOtp = () => {
    setLoginThroughOTP(true);
  };

  return (
    <div>
      {" "}
      <div>
        {errorMessage ? (
          <InternetIssue />
        ) : (
          <>
            <div className="container-fluid">
              <div className="sigin-page-body">
                <div className="dflex-flow-row">
                  <Col
                    lg={5}
                    md={5}
                    sm={12}
                    className="signIn-container-left-div"
                  >
                    <SignInGifImag />
                  </Col>
                  <Col lg={2} md={2} sm={12}>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={5}
                    sm={12}
                    className="signIn-container-right-div"
                  >
                    <div className="metricinfo-logo">
                      <Image
                        src={travelize}
                        alt="MetricInfo Logo"
                        className="sb-trv-logo"
                      />
                    </div>
                    <div className="signin-header login-part">
                      <div className="signin-box">
                        {clientLoginUrl === "#/barrix" && (
                          <div className="whitle-labeling-logo">
                            <Image
                              src={clientLogo}
                              alt="MetricInfo"
                              className="sb-login-logo"
                              style={{ width: "180px", height: "70px" }}
                            />
                          </div>
                        )}
                        <div className="login-header-part">
                          <h2 className="mb-1">Welcome back to VHD</h2>
                          <h4>Please sign in to continue</h4>
                        </div>
                        <form
                          onSubmit={loginValidation}
                          className="login-form"
                          autoComplete="off"
                        >
                          <div className="form-group mb20">
                            <div className="icon-with-input before">
                              <i className="fa fa-envelope" />
                              <input
                                type="email"
                                className="form-control signIn-form-control"
                                placeholder="Enter your email"
                                value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                onChange={handleEmailChange}
                                required
                                maxLength={50}
                                name="email" // Add name attribute
                                autoComplete="username" // Add autocomplete attribute
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            {!loginThroughOTP ? (
                              <div className="icon-with-input before">
                                <i className="fa fa-lock"></i>
                                <input
                                  type={passwordType}
                                  className="form-control signIn-form-control"
                                  placeholder="Enter your password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                  maxLength={20}
                                  minLength={6}
                                  name="password" // Add name attribute
                                  autoComplete="current-password" // Add autocomplete attribute
                                />
                                <div className="pass-hide-show">
                                  {passwordType === "password" ? (
                                    <i
                                      className="bi bi-eye-slash pass-hide-show"
                                      onClick={togglePassword}
                                    />
                                  ) : (
                                    <i
                                      className="bi bi-eye pass-hide-show"
                                      onClick={togglePassword}
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="login-with-otp-part">
                                {" "}
                                <Form className="enter-otp icon-with-input">
                                  <div className="otp-label">
                                    Enter OTP Here
                                  </div>
                                  <div className="form-field-div">
                                    {otp.map((value, index) => (
                                      <input
                                        key={index}
                                        type="password"
                                        value={value}
                                        maxLength={1}
                                        className="form-control otp-input-field"
                                        ref={(input) =>
                                          (inputRefs.current[index] = input)
                                        }
                                        onChange={(e) =>
                                          handleOtpChange(index, e.target.value)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(index, e)
                                        }
                                      />
                                    ))}
                                  </div>
                                  {otpError && (
                                    <small
                                      id={`Error`}
                                      className="form-text text-muted "
                                    >
                                      Entered OTP is incorrect
                                    </small>
                                  )}
                                  {otpStatus ? (
                                    <>
                                      <OTPTimer
                                        seconds={seconds}
                                        setSeconds={setSeconds}
                                        onTimerExpire={handleTimerExpire}
                                        showTimer={showTimer}
                                        getOtpForSuperAdmin={
                                          getOtpForSuperAdmin
                                        }
                                      />
                                    </>
                                  ) : (
                                    <SBSaveUpdateBtn
                                      btnName="Get OTP"
                                      onClickEffect={getOtpForSuperAdmin}
                                    />
                                  )}
                                </Form>
                              </div>
                            )}
                          </div>
                          {!loginThroughOTP ? (
                            <div className="forgot-pass-n-get-otp dflex-j-between">
                              <div className="forget-pasword-div">
                                <Link
                                  to={
                                    clientLoginUrl === "#/barrix"
                                      ? "/barixforgotpassword"
                                      : "/updatedforgotPassword"
                                  }
                                  className="forgot-pass-text color-yellow"
                                >
                                  Forgot Password?
                                </Link>
                              </div>

                              <div className="sign-in--using-otp-div">
                                <Button
                                  className={`otp-signin`}
                                  onClick={() =>
                                    setLoginThroughOTP(!loginThroughOTP)
                                  }
                                >
                                  Sign In using OTP
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="hv-center mt-1">
                              <div
                                className="back-to-signin-with-pass color-yellow"
                                onClick={() =>
                                  setLoginThroughOTP(!loginThroughOTP)
                                }
                              >
                                Back to login with password
                              </div>
                            </div>
                          )}

                          <div className="signin-button mb20 hv-center">
                            <Button
                              type="submit"
                              className="btn btn-signin-button"
                            >
                              Sign In
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
