import React, { useState, useEffect } from "react";
import { Col, Row, FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import uuid from "react-uuid";
import { authOptions } from "../../DropdownsData";

export default function Authorization() {
  const [apiKeyValData, setAPIKeyValData] = useState({
    keyName: "",
    valueOfKey: "",
  });
  const [paramsKeyValData, setParamsKeyValData] = useState([
    { id: uuid(), keyName: "", valueOfKey: "" },
  ]);

  const [authType, setAuthType] = useState({ value: null, label: "" });

  const addAnotherRow = (e) => {
    e.preventDefault();
    setParamsKeyValData([
      ...paramsKeyValData,
      { id: uuid(), keyName: "", valueOfKey: "" },
    ]);
  };

  const removeRow = (i) => {
    let finalKeyValues = paramsKeyValData.filter((item, index) => index !== i);
    setParamsKeyValData(finalKeyValues);
  };

  const handleInputData = (e) => {
    const { name, value } = e.target;
    const { keyName, valueOfKey } = { name, value };
    setAPIKeyValData({ keyName, valueOfKey });
    // let keyData = paramsKeyValData.filter((item) => item.id == inputId);
    // keyData[0][name] = value;
    // setParamsKeyValData([...paramsKeyValData]);
  };

  //   console.log("paramsKeyValData:", paramsKeyValData);

  return (
    <div className="authorization-part">
      <div className="table-heading">
        <span>Authorization</span>
      </div>
      <div className="row auth-type-row">
        <div className="col-md-4 my-3 left">
          <FloatingLabel
            label="Select Type"
            className={`${
              authType ? "float-visible-select" : "float-hidden float-select"
            }`}
          >
            <Select
              className="react-select-container-list model-select"
              classNamePrefix="react-select-list"
              name="authType"
              options={authOptions}
              placeholder="Select Type"
              value={authType.value ? authType : ""}
              onInputChange={authOptions.label}
              onChange={(data) => setAuthType(data)}
              isSearchable={true}
            />
          </FloatingLabel>
        </div>
        <div className="col-md-8 my-3 right">
          {authType.value === "API Key" ? (
            <div className="key-n-value-data">
              <div className="key-n-value-table-head">
                <Row>
                  <Col className="tb-hd">
                    <span className="label-head">KEY</span>
                  </Col>
                  <Col className="tb-hd">
                    {" "}
                    <span className="label-head">VALUE</span>
                  </Col>
                </Row>
              </div>
              <div className="key-n-value-inputs">
                <Row className="row-values">
                  <Col className="tb-data">
                    <span className="label-val">
                      <input
                        type="text"
                        name="apiKeyName"
                        autoComplete="off"
                        className="form-control form add-input"
                        placeholder="Key"
                        value={apiKeyValData?.keyName}
                        onChange={(e) => handleInputData(e)}
                      />
                    </span>
                  </Col>
                  <Col className="tb-data">
                    {" "}
                    <div>
                      <span className="label-val">
                        {" "}
                        <input
                          type="text"
                          name="apiValueOfKey"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Value"
                          value={apiKeyValData?.valueOfKey}
                          onChange={(e) => handleInputData(e)}
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : authType.value === "Bearer Token" ? (
            <>
              <div className="dflex-j-between" style={{ width: "100%" }}>
                <div className="p-1">
                  <span>Token</span>
                </div>
                <div className="p-1" style={{ width: "100%" }}>
                  <input
                    type="text"
                    name="bearerToken"
                    autoComplete="off"
                    className="form-control form add-input"
                    placeholder="Token"
                    // value={apiKeyValData?.valueOfKey}
                    onChange={(e) => handleInputData(e)}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
