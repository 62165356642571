import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import "../../../styles/VhdPODetails.scss";

export default function PODetailsTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
          //   onScroll={(e) =>
          //     handleScroll(
          //       e,
          //       props.users,
          //       props.totalUserCount,
          //       props.setLoading,
          //       props.setPageSize,
          //       props.PageSize
          //     )
          //   }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="po-details-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <div className="line-num-n-check-box">
                        <div>
                          {/* <Form.Check
                            inline
                            label={data?.row_id}
                            value={data?.row_id}
                            name="lineNum"
                            type="checkbox"
                            id={`inlineradio-poLineDetails-${i}`}
                            // onChange={handleInputData}
                            // checked={item?.value == enableStatus ? true : false}
                          /> */}
                          {data?.sl_no}
                        </div>
                        {/* <div>{data?.sl_no}</div> */}
                      </div>
                    </TableCell>
                    <TableCell>{data?.item_Description}</TableCell>
                    <TableCell>{data?.quantity_Ordered}</TableCell>
                    <TableCell>{data?.discount}</TableCell>
                    <TableCell>{data?.unit_Price}</TableCell>
                    <TableCell>{data?.line_Total}</TableCell>
                    <TableCell>{data?.tax_Code}</TableCell>
                    <TableCell>{data?.tax_Per}</TableCell>
                    <TableCell>{data?.tax_Amount}</TableCell>
                    <TableCell>{data?.gross_Total}</TableCell>
                    <TableCell>{data?.uom}</TableCell>
                    <TableCell align="center">
                      <div className={`po ${data?.lineitem_Status}`}>
                        {data?.lineitem_Status}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
