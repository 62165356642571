import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { exportExcel } from "../../DownloadFile";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SbDropDownBtn from "../../SbButtons/SbDropDownBtn";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { activeInactiveOptions } from "../../DropdownsData";
import { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import VendorTable from "./VendorTable";
import AddEditVendorModule from "./AddEditVendorModule";
import { setWidthToMaxContent } from "../VhdCommonStyles";
import {
  loadStateList,
  loadCityList,
  loadUserDropdown,
  loadAllVendorDetails,
} from "../../../redux/action";
import "../../../styles/VhdVendors.scss";

export default function VhdVendor() {
  const tableHeader = [
    { name: "Vendor Code", align: "left", width: setWidthToMaxContent },
    { name: "Vendor Name", align: "left", width: setWidthToMaxContent },
    { name: "Contact Person", align: "left", width: setWidthToMaxContent },
    { name: "Country", align: "left", width: setWidthToMaxContent },
    { name: "State", align: "left", width: setWidthToMaxContent },
    { name: "City", align: "left", width: setWidthToMaxContent },
    { name: "Location", align: "left", width: "150px" },
    { name: "Mapped To", align: "center", width: "auto" },
    { name: "Status", align: "center", width: "auto" },
    { name: "Action", align: "center", width: "auto" },
  ];

  let dispatch = useDispatch();
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);
  const { userDropdown } = useSelector((state) => state.data);
  const { getAllVendorDetails } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [filterUserId, setFilterUserId] = useState("");
  const [stateID, setStateID] = useState("");
  const [cityID, setCityID] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [addEditVendor, setAddEditVendor] = useState(false);
  const [vendorID, setVendorID] = useState(null);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [totalData, setTotalData] = useState(0);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadStateList(0));
    dispatch(loadCityList(0));
    dispatch(loadUserDropdown());
  }, []);

  useEffect(() => {
    fetchVendorDetails();
  }, [
    PageSize,
    currentPage,
    setTotalData,
    stateID,
    cityID,
    activeStatus,
    searchValue,
  ]);

  const fetchVendorDetails = () => {
    dispatch(
      loadAllVendorDetails(
        PageSize,
        currentPage,
        setTotalData,
        stateID,
        cityID,
        activeStatus,
        searchValue
      )
    );
  };

  // console.log("VENDOR-DATA:", getAllVendorDetails);

  const openCloseAddEditModel = () => {
    setAddEditVendor(!addEditVendor);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setStateID("");
    setCityID("");
    setActiveStatus("");
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      {!addEditVendor ? (
        <>
          <>
            {/* SIDEBAR - TOPBAR - FOOTER  */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            <Footer />
            {/* SIDEBAR - TOPBAR - FOOTER  */}
          </>
          <>
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={4} sm={4} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToSettingPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">VENDORS</h6>
                        </div>
                      </Col>
                      <Col md={5} sm={4} className="cat-col">
                        <div className="input-div">
                          <input
                            type="search"
                            id="metricInfo_search_bar"
                            placeholder="Search by VendorName / Code / Mobile / Email"
                            className="form-control setting-input"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <i className="fa fa-search search-icon" />
                        </div>
                      </Col>
                      <Col md={3} sm={4} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <div className="filter-icon-text">
                                <i className="bi bi-sliders" />
                                <span>Filters</span>
                              </div>
                            </div>

                            <SbDropDownBtn
                              pageName="Vendor"
                              openAddModal={openCloseAddEditModel}
                              // addBulkModel={handleCloseBulkUserModel}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={stateList}
                              placeholder="Select State"
                              value={stateID === "" ? "" : stateList.value}
                              onInputChange={stateList.label}
                              onChange={(data) => setStateID(data.label)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={cityList}
                              placeholder="Select City"
                              value={cityID === "" ? "" : cityList.value}
                              onInputChange={cityList.label}
                              onChange={(data) => setCityID(data.label)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={activeInactiveOptions}
                              placeholder="Select Status"
                              value={
                                activeStatus === ""
                                  ? ""
                                  : activeInactiveOptions.value
                              }
                              onInputChange={activeInactiveOptions.label}
                              onChange={(data) => setActiveStatus(data.label)}
                              isSearchable={true}
                            />
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}

                  {/* TABLE START */}
                  <div className="vendor-table-part">
                    <VendorTable
                      tableHeader={tableHeader}
                      tableData={getAllVendorDetails}
                      setVendorID={setVendorID}
                      openCloseAddEditModel={openCloseAddEditModel}
                      setWidthToMaxContent={setWidthToMaxContent}
                      setLoading={setLoading}
                      setPageSize={setPageSize}
                      PageSize={PageSize}
                      totalData={totalData}
                    />

                    <div className="download-and-pagination">
                      <SbDownloadBtn
                        btnName="Download"
                        // onClickEffect={exportUserDetails}
                      />
                    </div>
                  </div>
                  {/* TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              <></>
              {/* MAIN BODY END */}
            </div>
          </>
        </>
      ) : (
        <AddEditVendorModule
          vendorID={vendorID}
          handleClose={openCloseAddEditModel}
          setVendorID={setVendorID}
          fetchVendorDetails={fetchVendorDetails}
        />
      )}
    </div>
  );
}
