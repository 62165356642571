import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { handleScroll } from "../../OnScrollLoading";
import { baseURL } from "../../BaseUrl";

export default function InvoiceStatusTable(props) {
  return (
    <div>
      <div className="sb-table-div sb-table-setion">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className="table-main-div"
        >
          <TableContainer
            onScroll={(e) =>
              handleScroll(
                e,
                props.tableData,
                props.totalData,
                props.setLoading,
                props.setPageSize,
                props.PageSize
              )
            }
          >
            <Table
              stickyHeader
              aria-label="sticky table table-responsive"
              className="invoice-status-table"
            >
              <TableHead className="custom-table-header">
                <TableRow>
                  {props.tableHeader.map((data, i) => (
                    <TableCell key={i} align={data?.align} sx={data.width}>
                      {data?.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData?.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell sx={props.setWidthToMaxContent}>
                      <div className="color-green">
                        <b>{data?.invoice_number}</b>
                      </div>
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {moment(data?.invoice_date).format("MMM-DD-YYYY") ?? "-"}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      <div className="color-green"> {data?.po_Number}</div>
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {moment(data?.po_Date).format("MMM-DD-YYYY") ?? "-"}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_id}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.vendor_Name}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.invoice_amount}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.tax}
                    </TableCell>
                    <TableCell sx={props.setWidthToMaxContent}>
                      {data?.grand_Total}
                    </TableCell>
                    <TableCell align="center">
                      <div className="attachment">
                        {data?.attachment && (
                          <div
                            className="attachment"
                            onClick={() =>
                              window.open(
                                `${baseURL}/${data?.attachment}`,
                                "_blank"
                              )
                            }
                          >
                            <i className="fa fa-paperclip" />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="attachment">
                        {data?.pO_Attachment && (
                          <div
                            className="pO_Attachment"
                            onClick={() =>
                              window.open(
                                `${baseURL}/${data?.pO_Attachment}`,
                                "_blank"
                              )
                            }
                          >
                            <i className="fa fa-paperclip" />
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "120px" }}>
                      <div
                        className={`invoice depat-status ${data.invoice_status}`}
                      >
                        <span className="status-text">
                          {data?.invoice_status}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "120px" }}>
                      <div
                        className={`invoice finan-status ${data.invoice_status}`}
                      >
                        <span className="status-text">
                          {data?.invoice_status}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
