import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import SalesBee from "../../images/MatricLogo.svg";
import "../../styles/AddEditFormComponent.scss";

export default function AddEditFormComponent(props) {
  // const [tabKey, setTabKey] = useState(props.sidebarItems[0]);
  const [tabKey, setTabKey] = useState(
    props.selectNavKey ?? props.sidebarItems[0]
  );

  const tabEventKey = (val) => {
    setTabKey(val);
    {
      props.sidebarItems.length > 1 && props.setSelectNavKey(val);
    }
  };

  useEffect(() => {
    setTabKey(props.selectNavKey);
  }, [props.selectNavKey]);

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div>
      <div className="add-edit-details-container">
        <div className="add-edit-menu-bar">
          <div className="app-logo">
            <div className="logo-part">
              <Link className="travelize_logo" to="/dashboard">
                <img
                  src={SalesBee}
                  alt="product-title-img"
                  className="salesbee-logo"
                />
              </Link>
            </div>
          </div>

          <div className="add-edit-details-menu">
            {props.sidebarItems.map((data, i) => (
              <div
                className={
                  tabKey === data
                    ? `add-edit-menu-item active`
                    : `add-edit-menu-item`
                }
                key={i}
                onClick={() => tabEventKey(data)}
              >
                <p className="item-num">
                  <span>{i + 1}</span>
                </p>
                <div className="menu-text">{data}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="add-edit-details-form-header">
          <div className="back-btn-n-page-header">
            <div className="back-btn" onClick={closeModel}>
              <img
                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                alt="back-button-icon"
              />
            </div>
            <div>
              <h4>{props.formName}</h4>
              <p>{props.formSubTitle}</p>
            </div>
          </div>
        </div>

        <div className="addedit-form-body">
          <div className="add-edit-form-part">{props.childrens}</div>
        </div>
      </div>
    </div>
  );
}
