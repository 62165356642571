import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { exportExcel } from "../../DownloadFile";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SbDropDownBtn from "../../SbButtons/SbDropDownBtn";
import BranchTable from "./BranchTable";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { activeInactiveOptions } from "../../DropdownsData";
import { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { branchTableData } from "../TestData";
import AddBranchModel from "./AddBranchModel";
import {
  loadAllBranchDetails,
  loadAllBranchDropdown,
  loadCityList,
  loadStateList,
  loadZoneList,
} from "../../../redux/action";

export default function VhdBranch() {
  const tableHeader = [
    { name: "Branch", align: "left" },
    { name: "Point of Contact", align: "left" },
    { name: "Country", align: "left" },
    { name: "Zone", align: "left" },
    { name: "State", align: "left" },
    { name: "City", align: "left" },
    { name: "Location", align: "left" },
    { name: "Status", align: "center" },
    { name: "Action", align: "center" },
  ];

  let dispatch = useDispatch();

  const { getAllBrnachDetails } = useSelector((state) => state.data);
  const { getAllBranchDropdown } = useSelector((state) => state.data);
  const { zoneList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [zoneID, setZoneID] = useState("");
  const [stateID, setStateID] = useState("");
  const [cityID, setCityID] = useState("");
  const [branch, setBranch] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [branchID, setBranchID] = useState(null);
  const [addEditBranch, setAddEditBranch] = useState(false);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [totalData, setTotalData] = useState(0);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadAllBranchDropdown());
    dispatch(loadZoneList());
    dispatch(loadStateList());
    dispatch(loadCityList());
  }, []);

  useEffect(() => {
    fetchBranchDetails();
  }, [
    PageSize,
    currentPage,
    setTotalData,
    branch,
    zoneID,
    stateID,
    cityID,
    activeStatus,
    searchValue,
  ]);

  const fetchBranchDetails = () => {
    dispatch(
      loadAllBranchDetails(
        PageSize,
        currentPage,
        setTotalData,
        branch,
        zoneID,
        stateID,
        cityID,
        activeStatus,
        searchValue
      )
    );
  };

  const openCloseAddEditModel = () => {
    setAddEditBranch(!addEditBranch);
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setBranch("");
    setZoneID("");
    setStateID("");
    setCityID("");
    setActiveStatus("");
    setSearchValue("");
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      {!addEditBranch ? (
        <>
          <>
            {/* SIDEBAR - TOPBAR - FOOTER  */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />

            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            <Footer />
            {/* SIDEBAR - TOPBAR - FOOTER  */}
          </>
          <>
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={4} sm={4} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToSettingPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">BRANCH</h6>
                        </div>
                      </Col>
                      <Col md={5} sm={4} className="cat-col">
                        <div className="input-div">
                          <input
                            type="search"
                            id="metricInfo_search_bar"
                            placeholder="Search by Person Name / Number / Email"
                            className="form-control setting-input"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <i className="fa fa-search search-icon" />
                        </div>
                      </Col>
                      <Col md={3} sm={4} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <div className="filter-icon-text">
                                <i className="bi bi-sliders" />
                                <span>Filters</span>
                              </div>
                            </div>

                            <SbDropDownBtn
                              pageName="Branch"
                              openAddModal={openCloseAddEditModel}
                              // addBulkModel={handleCloseBulkUserModel}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={getAllBranchDropdown}
                              placeholder="Select Branch"
                              value={
                                branch === "" ? "" : getAllBranchDropdown.value
                              }
                              onInputChange={getAllBranchDropdown.label}
                              onChange={(data) => setBranch(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={zoneList}
                              placeholder="Select Zone"
                              value={zoneID === "" ? "" : zoneList.value}
                              onInputChange={zoneList.label}
                              onChange={(data) => setZoneID(data.label)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={stateList}
                              placeholder="Select State"
                              value={stateID === "" ? "" : stateList.value}
                              onInputChange={stateList.label}
                              onChange={(data) => setStateID(data.label)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={cityList}
                              placeholder="Select City"
                              value={cityID === "" ? "" : cityList.value}
                              onInputChange={cityList.label}
                              onChange={(data) => setCityID(data.label)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={activeInactiveOptions}
                              placeholder="Select Status"
                              value={
                                activeStatus === "" ? "" : activeStatus.value
                              }
                              onInputChange={activeInactiveOptions.label}
                              onChange={(data) => setActiveStatus(data.label)}
                              isSearchable={true}
                            />
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}

                  {/* TABLE START */}

                  <BranchTable
                    tableHeader={tableHeader}
                    tableData={getAllBrnachDetails}
                    setBranchID={setBranchID}
                    openCloseAddEditModel={openCloseAddEditModel}
                    setLoading={setLoading}
                    setPageSize={setPageSize}
                    PageSize={PageSize}
                    totalData={totalData}
                  />

                  <div className="user-table">
                    <div className="download-and-pagination">
                      <SbDownloadBtn
                        btnName="Download"
                        // onClickEffect={exportUserDetails}
                      />
                    </div>
                  </div>
                  {/* TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              <></>
              {/* MAIN BODY END */}
            </div>
          </>
        </>
      ) : (
        <AddBranchModel
          branchID={branchID}
          handleClose={openCloseAddEditModel}
          setBranchID={setBranchID}
          fetchBranchDetails={fetchBranchDetails}
        />
      )}
    </div>
  );
}
