import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/VhdBranch.scss";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import {
  settingNameForurl,
  vhdModuleNames,
  methodsList,
} from "../../DropdownsData";
import QueryParams from "./QueryParams";
import Authorization from "./Authorization";
import Headers from "./Headers";
import Body from "./Body";

export default function AddUrlToModule(props) {
  let dispatch = useDispatch();

  const [settingName, setSettingName] = useState(null);
  const [moduleName, setModuleName] = useState({ value: null, label: "" });
  const [method, setMethod] = useState({ value: null, label: "" });
  const [state, setState] = useState({ value: null, label: "" });
  const [city, setCity] = useState({ value: null, label: "" });

  const [urlSettingData, setUrlSettingData] = useState({
    vhdModuleName: "",
    moduleCode: "",
    urlMethod: "",
    url: "",
  });

  const { vhdModuleName, moduleCode, urlMethod, url } = urlSettingData;

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setUrlSettingData({ ...urlSettingData, [name]: value });
  };

  const handleSelectChange = (data, type) => {
    if (type === "moduleName") {
      setModuleName(data);
      urlSettingData.vhdModuleName = data.value;
    }
    if (type === "method") {
      setMethod(data);
      urlSettingData.urlMethod = data.value;
    }
  };

  const addUrlSettings = () => {
    console.log("ADD-DATA:", "api data come");
  };

  const closeModel = () => {
    props.handleClose();
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={"ADD URL"}
        formSubTitle={"Add url with get or post method by selected module"}
        sidebarItems={["ADD URL"]}
        childrens={
          <>
            <div className="url-settings-for-modules">
              <div className="row">
                <div className="col my-3">
                  <FloatingLabel
                    label="Select Module"
                    className={`${
                      vhdModuleName
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      name="moduleName"
                      options={vhdModuleNames}
                      placeholder="Select Module"
                      value={moduleName.value ? moduleName : ""}
                      onInputChange={vhdModuleNames.label}
                      onChange={(data) =>
                        handleSelectChange(data, "moduleName")
                      }
                      isSearchable={true}
                    />
                  </FloatingLabel>
                </div>
                <div className="col my-3 method-options dflex-j-start">
                  <div className="method-label mb-1">Method :</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {methodsList.map((item, i) => (
                    <div key={i}>
                      <Form.Check
                        inline
                        label={item?.label}
                        value={item?.value}
                        name="urlMethod"
                        type="radio"
                        id={`inlineradio-method-${i}`}
                        onChange={handleInputData}
                        checked={item?.value == urlMethod ? true : false}
                      />
                    </div>
                  ))}
                </div>
                {/* <div className="col my-3">
                  <FloatingLabel
                    label="Select Method"
                    className={`${
                      urlMethod
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      name="method"
                      options={methodsList}
                      placeholder="Select Method"
                      value={method.value ? method : ""}
                      onInputChange={methodsList.label}
                      onChange={(data) => handleSelectChange(data, "method")}
                      isSearchable={true}
                    />
                  </FloatingLabel>
                </div>
                <div className="col my-3">
                  <FloatingLabel
                    label="Module Code"
                    className={`${
                      moduleCode
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control form add-input"
                      placeholder="Module Code"
                      value={moduleCode}
                      onChange={(e) => handleInputData(e)}
                      required
                    />
                  </FloatingLabel>
                </div> */}
              </div>
              <div className="row">
                <div className="col my-3">
                  <FloatingLabel
                    label="Enter or paste URL"
                    className={`${
                      url ? "float-input-visible" : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="text"
                      autoComplete="off"
                      id="addUrlorPaseUrl"
                      className="form-control form add-input"
                      placeholder="Enter or paste URL"
                      value={url}
                      onChange={(e) => handleInputData(e)}
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="row">
                <div className="select-param-header-auth">
                  {settingNameForurl.map((item, i) => (
                    <div
                      className="setting-name"
                      key={i}
                      onClick={() => setSettingName(item.value)}
                    >
                      <span
                        className={
                          settingName === item.value ? `name active` : "name"
                        }
                      >
                        {item?.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="enter-settings-details">
                  {settingName === "Params" && (
                    <>
                      <QueryParams />
                    </>
                  )}
                  {settingName === "Auth" && (
                    <>
                      <Authorization />
                    </>
                  )}
                  {settingName === "Headers" && (
                    <>
                      <Headers />
                    </>
                  )}
                  {settingName === "Body" && (
                    <>
                      <Body />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}
