import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import uuid from "react-uuid";

export default function Body() {
  const bodyOptions = [
    {
      value: "none",
      label: "none",
    },
    {
      value: "form-data",
      label: "form-data",
    },
    {
      value: "raw",
      label: "raw",
    },
    {
      value: "binary",
      label: "binary",
    },
  ];

  const [paramsKeyValData, setParamsKeyValData] = useState([
    { id: uuid(), keyName: "", valueOfKey: "" },
  ]);

  const [selectedBodyOption, setSelectedBodyOption] = useState("");
  const [bodyData, setBodyData] = useState({
    rawData: "",
  });

  const addAnotherRow = (e) => {
    e.preventDefault();
    setParamsKeyValData([
      ...paramsKeyValData,
      { id: uuid(), keyName: "", valueOfKey: "" },
    ]);
  };

  const removeRow = (i) => {
    let finalKeyValues = paramsKeyValData.filter((item, index) => index !== i);
    setParamsKeyValData(finalKeyValues);
  };

  const handleInputKeyData = (e, inputId) => {
    const { name, value } = e.target;
    let keyData = paramsKeyValData.filter((item) => item.id == inputId);
    keyData[0][name] = value;
    setParamsKeyValData([...paramsKeyValData]);
  };

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setBodyData({ ...bodyData, [name]: value });
  };

  //   console.log("paramsKeyValData:", paramsKeyValData);

  return (
    <div className="query-param-part">
      <div className="table-heading">
        <span>Headers</span>
      </div>
      <div className="dflex-j-start">
        {bodyOptions.map((item, i) => (
          <div key={i}>
            <Form.Check
              inline
              label={item?.label}
              value={item?.value}
              name="bodyOption"
              type="radio"
              id={`inlineradio-bodyOption-${i}`}
              onChange={(e) => setSelectedBodyOption(e.target.value)}
              checked={item?.value == selectedBodyOption ? true : false}
            />
          </div>
        ))}
      </div>
      <div>
        {selectedBodyOption === "form-data" && (
          <div className="key-n-value-data">
            <div className="key-n-value-table-head">
              <Row>
                <Col className="tb-hd">
                  <span className="label-head">KEY</span>
                </Col>
                <Col className="tb-hd">
                  {" "}
                  <span className="label-head">VALUE</span>
                </Col>
              </Row>
            </div>
            <div className="key-n-value-inputs">
              {paramsKeyValData.map((data, i) => (
                <Row key={i} className="row-values">
                  <Col className="tb-data">
                    <span className="label-val">
                      <input
                        type="text"
                        name="keyName"
                        autoComplete="off"
                        className="form-control form add-input"
                        placeholder="Key"
                        value={data?.keyName}
                        onChange={(e) => handleInputKeyData(e, data.id)}
                      />
                    </span>
                  </Col>
                  <Col className="tb-data">
                    {" "}
                    <div className="dflex-j-between" style={{ width: "100%" }}>
                      <span className="label-val" style={{ width: "95%" }}>
                        {" "}
                        <input
                          type="text"
                          name="valueOfKey"
                          autoComplete="off"
                          className="form-control form add-input"
                          placeholder="Value"
                          value={data?.valueOfKey}
                          onChange={(e) => handleInputKeyData(e, data.id)}
                        />
                      </span>

                      <span
                        className="remove-row"
                        style={{ width: "5%" }}
                        onClick={(e) => removeRow(i)}
                      >
                        <i className="fa fa-trash" />
                      </span>
                      <span
                        className="add-another-row"
                        style={{ width: "5%" }}
                        onClick={addAnotherRow}
                      >
                        <i className="fa fa-plus-circle" />
                      </span>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        )}
        {selectedBodyOption === "raw" && (
          <>
            <textarea
              rows="15"
              cols="20"
              wrap="hard"
              name="rawData"
              className="form-control form-textarea"
              id="bodyRawData"
              placeholder=""
              //   value={location}
              onChange={(e) => handleInputData(e)}
            />
          </>
        )}
      </div>
    </div>
  );
}
