import React, { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { exportExcel } from "../../DownloadFile";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SbDropDownBtn from "../../SbButtons/SbDropDownBtn";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { poStatusOptions } from "../../DropdownsData";
import { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { poTableData } from "../TestData";
import PurchaseOrderTable from "./PurchaseOrderTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddEditPOModel from "./AddEditPOModel";
import "../../../styles/VhdPurchaseOrder.scss";
import { setWidthToMaxContent } from "../VhdCommonStyles";
import {
  loadAllBranchDropdown,
  loadAllPODetails,
  loadAllVendorDropdown,
} from "../../../redux/action";
import moment from "moment";

export default function VhdPurchaseOrder() {
  let dispatch = useDispatch();

  const { getAllVendorDropdown } = useSelector((state) => state.data);
  const { getAllBranchDropdown } = useSelector((state) => state.data);
  const { getAllPODetails } = useSelector((state) => state.data);

  const tableHeader = [
    {
      name: "Po.DocEntry",
      align: "left",
      style: setWidthToMaxContent,
    },
    {
      name: "Doc.No",
      align: "left",
      style: setWidthToMaxContent,
    },
    {
      name: "Date",
      align: "left",
      style: setWidthToMaxContent,
    },
    {
      name: "Vendor Code",
      align: "left",
      style: setWidthToMaxContent,
    },
    {
      name: "Vendor Name",
      align: "left",
      style: setWidthToMaxContent,
    },
    { name: "Ship.To", align: "left", style: "auto" },
    { name: "Bill.To", align: "left", style: "auto" },
    {
      name: "PO Value",
      align: "left",
      style: setWidthToMaxContent,
    },
    {
      name: "Tax Amount (₹)",
      align: "left",
      style: setWidthToMaxContent,
    },
    { name: "Doc.Total", align: "left", style: "auto" },
    { name: "Remarks", align: "left", style: "auto" },
    { name: "Status", align: "center", style: "auto" },
    { name: "Action", align: "center", style: "auto" },
  ];

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [vendorName, setVendorName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [poStatus, setPOStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "M")._d
  );
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  console.log("1 Month Back:", moment(new Date()).subtract(1, "M")._d);

  const [addEditPO, setAddEditPO] = useState(false);
  const [purchaseOrderID, setPurchaseOrderID] = useState(null);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [totalData, setTotalData] = useState(0);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const openCloseAddEditModel = () => {
    setAddEditPO(!addEditPO);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadAllVendorDropdown());
    dispatch(loadAllBranchDropdown());
  }, []);

  useEffect(() => {
    fetchPODetails();
  }, [
    PageSize,
    currentPage,
    setTotalData,
    vendorName,
    poStatus,
    branchName,
    searchValue,
    fromDate,
    toDate,
  ]);

  const fetchPODetails = () => {
    dispatch(
      loadAllPODetails(
        PageSize,
        currentPage,
        setTotalData,
        vendorName,
        poStatus,
        branchName,
        searchValue,
        moment(fromDate).format("MM/DD/YYYY"),
        moment(toDate).format("MM/DD/YYYY")
      )
    );
  };

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setVendorName("");
    setBranchName("");
    setPOStatus("");
    setSearchValue("");
    setFromDate(moment(new Date()).subtract(1, "M")._d);
    setToDate(new Date());
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      {!addEditPO ? (
        <>
          <>
            {/* SIDEBAR - TOPBAR - FOOTER  */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            <Footer />
            {/* SIDEBAR - TOPBAR - FOOTER  */}
          </>
          <>
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={4} sm={12} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToSettingPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">Purchase Orders</h6>
                        </div>
                      </Col>
                      <Col md={5} sm={12} className="cat-col">
                        <div className="input-div">
                          <input
                            type="search"
                            id="metricInfo_search_bar"
                            placeholder="Search by PO Number / Vendor Name"
                            className="form-control setting-input"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <i className="fa fa-search search-icon" />
                        </div>
                      </Col>
                      <Col md={3} sm={12} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <div className="filter-icon-text">
                                <i className="bi bi-sliders" />
                                <span>Filters</span>
                              </div>
                            </div>

                            <SbDropDownBtn
                              pageName="PO"
                              openAddModal={openCloseAddEditModel}
                              // addBulkModel={handleCloseBulkUserModel}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div className="filter-row-input-boxes">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={getAllVendorDropdown}
                              placeholder="Select Vendor"
                              value={
                                vendorName === ""
                                  ? ""
                                  : getAllVendorDropdown.value
                              }
                              onInputChange={getAllVendorDropdown.label}
                              onChange={(data) => setVendorName(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={getAllBranchDropdown}
                              placeholder="Select Branch"
                              value={
                                branchName === ""
                                  ? ""
                                  : getAllBranchDropdown.value
                              }
                              onInputChange={getAllBranchDropdown.label}
                              onChange={(data) => setBranchName(data.value)}
                              isSearchable={true}
                            />
                          </div>

                          <div className="from-to-date-select">
                            <div className="input-group-text" id="from-label">
                              From - To
                            </div>
                            <div className="date-picker-with-icon">
                              <DatePicker
                                selected={fromDate}
                                className="select from-date date-filter"
                                id="date-range-picker"
                                dateFormat="dd/MM/yyyy"
                                selectsRange
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setSelectSearchDrop(date)}
                                // maxDate={new Date()}
                              />
                              <div>
                                <i
                                  className="fa fa-calendar-o calander-icon"
                                  style={{ right: "6px" }}
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={poStatusOptions}
                              placeholder="Select Status"
                              value={poStatus === "" ? "" : poStatus.value}
                              onInputChange={poStatusOptions.label}
                              onChange={(data) => setPOStatus(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}

                  {/* TABLE START */}
                  <div className="purchase-order-part">
                    <div className="po-table">
                      <PurchaseOrderTable
                        tableHeader={tableHeader}
                        tableData={getAllPODetails}
                        setWidthToMaxContent={setWidthToMaxContent}
                        openCloseAddEditModel={openCloseAddEditModel}
                        setPurchaseOrderID={setPurchaseOrderID}
                        setLoading={setLoading}
                        setPageSize={setPageSize}
                        PageSize={PageSize}
                        totalData={totalData}
                      />
                    </div>

                    <div className="download-and-pagination">
                      <SbDownloadBtn
                        btnName="Download"
                        // onClickEffect={exportUserDetails}
                      />
                    </div>
                  </div>
                  {/* TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              <></>
              {/* MAIN BODY END */}
            </div>
          </>
        </>
      ) : (
        <AddEditPOModel
          purchaseOrderID={purchaseOrderID}
          handleClose={openCloseAddEditModel}
          setPurchaseOrderID={setPurchaseOrderID}
          fetchPODetails={fetchPODetails}
        />
      )}
    </div>
  );
}
